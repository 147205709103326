import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export class Copyright extends Component {
  scrollToTarget(v_target) {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125, 
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <>
        <section className="copyright px-3 pt-2 pb-2 ibg-secondary itx-white">
          <Container fluid>
            <Row>
              <Col lg={6} md={12} className="text-center text-lg-start">
                <a href="#/" onClick={() => this.scrollToTarget("about")} className='me-2'>{this.props.lang === 'th' ? "เกี่ยวกับเรา" : "About us"}</a>
                <a href="#/" onClick={() => this.scrollToTarget("product")} className='me-2'>{this.props.lang === 'th' ? "ผลิตภัณฑ์ออร์แกนิค" : "Organic Goods"}</a>
                <a href="#/" onClick={() => this.scrollToTarget("faq")} className='me-2'>{this.props.lang === 'th' ? "คำถามที่พบบ่อย" : "FAQ"}</a>
                <a href="#/" onClick={() => this.scrollToTarget("gallery")} className='me-2'>{this.props.lang === 'th' ? "ผลงาน" : "Gallery"}</a>
                <a href="#/" onClick={() => this.scrollToTarget("contact")} className='me-2'>{this.props.lang === 'th' ? "ติดต่อเรา" : "Contact us"}</a>
              </Col>
              <Col lg={6} md={12} className="text-center text-lg-end">Copyright&copy; 2023 by Piti Organic. All Rights Reserved.</Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Copyright