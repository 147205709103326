import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import DisplayContent from './DisplayContent';
import Deco7 from '../Images/deco/deco_7.png';

export class SectionAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  viewMore() {
    this.handleShow();
  }

  render() {
    return (
      <>
        <section id="about" className={"section section-about " + this.props.sectionGap}>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <div className="p-5">
                  <h5><img src={Deco7} className="me-3" style={{ height: '2rem' }} alt="deco" />{this.props.contents.name}</h5>
                  <h2 className='mb-4'>{this.props.contents.name2}</h2>
                  <p>
                    {this.props.contents.detail}
                    <br />
                    <button className="btn btn-sm ibt-primary px-3 mt-3" onClick={() => this.viewMore()}>รายละเอียด</button>
                  </p>
                </div>
              </Col>
              <Col lg={6} md={12}>
                {/* <div className="bg-img ratio-4x3 mb-2" style={{ backgroundImage: "url('" + urlApi + '/storage/media/' + item.set4 + "')" }}></div> */}
                <div className="bg-img ratio-4x3 mb-2 rounded-5" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + this.props.contents.row_value + "')" }}></div>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal show={this.state.modalShow} onHide={this.handleClose} fullscreen={true}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary text-center w-100">{this.props.contents.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark p-5" scrollable={true}>
            <Container>
              <DisplayContent contents={this.props.contents.contents} />
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

SectionAbout.defaultProps = {
  contents: {
    name: '', 
    name2: '', 
    detail: '', 
    row_value: '', 
    contents: []
  }
}

export default SectionAbout