import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Cert1 from '../Images/certify/cert1.jpg';

const SectionCertify = ({ contents }) => {
  return (
    <>
      <section className="pt-5 pb-5 mb-5">
        <Container>
          <Row>
            <Col className="text-center">
              {contents.map((item, index) => (
                <img src={urlApi + '/storage/media/' + item.set2} alt="Certify" className='ms-5 me-5 mb-4' style={{ width: '160px' }} key={index} />
              ))}
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <img src={Cert1} alt="Certify" style={{ width: '100%', maxWidth: '550px' }} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionCertify.defaultProps = {
  contents: []
}

export default SectionCertify