import React from 'react';
import DOMPurify from 'dompurify';

const HTMLContent = ({ htmlContent }) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);

  return (
    <div class="HTML-Displayer ql-editor" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default HTMLContent;