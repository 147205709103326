import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Modal, Button, Badge } from 'react-bootstrap';
import DisplayContent from './DisplayContent';
import Deco7 from '../Images/deco/deco_7.png';

export class SectionArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      displayContentName: '',
      displayContent: []
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  viewMore(v_item) {
    console.log(v_item);
    this.setState({
      displayContentName: v_item.name,
      displayContent: v_item.contents
    });
    this.handleShow();
  }

  render() {
    return (
      <>
        <section id="article" className={`section section-article pb-0`}>
          <Container className='pt-5'>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="text-center">
                <h5 className='mb-5'>คลังความรู้ <img src={Deco7} className="me-3" style={{ height: '2rem' }} alt="deco" /></h5>
              </Col>
            </Row>
            <Row>
              {this.props.contents.map((item, index) => (
                <Col lg={4} md={4} className="text-start" key={index} onClick={() => this.viewMore(item)}>
                  <div className="mb-5 ps-5 pe-5">
                    {/* <div className="img-frame mb-3" style={{backgroundImage: "url('" + urlApi + '/storage/media/' + item.set3 + "')"}}></div> */}
                    <div className="bg-img ratio-16x9 mb-3 rounded-2" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}></div>
                    <h6>{item.name}</h6>
                    <h6>
                      {item.categories.map((category) => (
                        <Badge pill className='px-3 ibg-secondary'>{category.name}</Badge>
                      ))}
                    </h6>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <Modal show={this.state.modalShow} onHide={this.handleClose} fullscreen={true}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary text-center w-100">{this.state.displayContentName}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark p-5" scrollable={true}>
            <Container>
              <DisplayContent contents={this.state.displayContent} />
            </Container>
            <Row>
              <Col className="text-center">
                <Button className="btn btn-sm ibt-primary px-3" onClick={this.handleClose}>กลับสู่หน้าหลัก</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

SectionArticle.defaultProps = {
  contents: []
}

export default SectionArticle