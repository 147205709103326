import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Deco3 from '../Images/deco/deco_3.png';
import Bg from '../Images/bg/bg-gs.webp';

export class SectionService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalTitle: '',
      modalImage: '',
      modalMessage: '',
      modalShow: false,
      modalGallery: [], 
      modalCategory: []
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }


  handleShow() {
    this.setState({ modalShow: true });
  }

  viewMore(v_item) {
    console.log(v_item);
    this.setState({
      modalTitle: v_item.name,
      modalMessage: v_item.detail,
      modalImage: v_item.row_value,
      modalGallery: v_item.images, 
      modalCategory: v_item.categories
    });
    this.handleShow();
  }

  viewImage(v_item) {
    console.log(v_item);
    this.setState({
      modalImage: v_item.row_value
    })
  }

  render() {
    return (
      <>
        <section className="section section-service bg-white bg-img" id="product" style={{ backgroundImage: "url('" + Bg + "')" }}>
          <Container>
            <Row>
              <Col className="text-center">
                <h5>ผลิตภัณฑ์ออร์แกนิค<img src={Deco3} className="ms-3" style={{ height: '2rem' }} alt="deco" /></h5>
                <h2 className='mb-5'>เกษตรแบบปลอดสารเคมี</h2>
              </Col>
            </Row>
            <Row>
              {this.props.contents.map((item, index) => (
                <Col lg={4} md={4} className="text-center" key={index} onClick={() => this.viewMore(item)}>
                  <div className="mb-5 ps-5 pe-5">
                    {/* <div className="img-frame mb-3" style={{backgroundImage: "url('" + urlApi + '/storage/media/' + item.set3 + "')"}}></div> */}
                    <div className="bg-img ratio-4x3 mb-3 rounded-5" style={{ backgroundImage: "url('" + urlApi + '/storage/product/' + item.row_value + "')" }}></div>
                    <h4>{item.name}</h4>
                    <p>{item.category_name}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <Modal show={this.state.modalShow} onHide={this.handleClose} fullscreen={true}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary text-center w-100">{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark p-5" scrollable={true}>
            <Row className="mb-3">
              <Col sm={{ span: 12, offset: 0 }} md={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
                <img src={urlApi + '/storage/product/' + this.state.modalImage} className="w-100 rounded" alt={this.state.modalTitle} />
                <Row className="mt-5 mb-4">
                  {this.state.modalGallery.map((item, index) => (
                    <Col xs={2} className="text-center" key={index}>
                      <div className="ict-gallery ratio-4x3"
                        style={{ backgroundImage: "url('" + urlApi + '/storage/product/' + item.row_value + "')" }}
                        onClick={() => this.viewImage(item)}
                      ></div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col sm={{ span: 12, offset: 0 }} md={{ span: 5}} xl={{ span: 5 }} className="tx-service-modal">
                <h4 className='mb-2'>{this.state.modalTitle}</h4>
                {this.state.modalCategory.map((item, index) => (
                  <span key={index} className="badge rounded-pill text-bg-secondary mb-3">{item.name}</span>
                ))}
                {this.state.modalMessage.split('\n').map((item, index) => (
                <p className="mb-2" key={index}>{this.state.modalMessage.split('\n')[index]}</p>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button className="btn btn-sm ibt-primary px-3" onClick={this.handleClose}>กลับสู่หน้าหลัก</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default SectionService