import React from 'react';
import { Col } from 'react-bootstrap';
import HTMLContent from './HTMLContent';

const ContentText = ({content, size}) => {
  console.log("ContentText :", content);
  return (
    <>
      <Col xs={12} sm={12} md={size} lg={size} xl={size} xxl={size} className="mb-3">
        <HTMLContent htmlContent={content} />
      </Col>
    </>
  )
}

ContentText.defaultProps = {
  content: '', 
  size: 12
}

export default ContentText