import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { BiPaperPlane } from 'react-icons/bi';

export class SectionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      name: '',
      contact_back: '', 
      modalTitle: '', 
      modalMessage: '', 
      modalButtonLabel: '', 
      modalShow: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentWillReceiveProps(props) {
    console.log("Form", props.lang);
    this.setState({
      modalTitle: props.lang === 'th' ? 'ดำเนินการเรียบร้อย' : 'Successful'
    });
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("messageSent", urlApi + '/?module=web&call=message-submit');

    var form_data = new FormData();
    form_data.append("subject", this.state.subject);
    form_data.append("name", this.state.name);
    form_data.append("phone", this.state.contact_back);

    var url = urlApi + '/?module=web&call=message-submit';

    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("messageSent", result.returnStatus.code);

        if(result.returnStatus.code === "0000") {
          this.setState({
            subject: '',
            name: '',
            contact_back: '', 
            modalShow: true, 
            modalTitle: this.props.lang === 'th' ? 'ดำเนินการเรียบร้อย' : 'Successful', 
            modalMessage: this.props.lang === 'th' ? 'ได้รับข้อความเรียบร้อย เราจะติดต่อกลับโดยเร็วที่สุด' : 'We have already received your message. We will contact you back immediately.', 
            modalButtonLabel: this.props.lang === 'th' ? 'ปิด' : 'Close'
          });
        } else {
          this.setState({
            modalShow: true, 
            modalTitle: this.props.lang === 'th' ? 'ไม่สามารถดำเนินการได้' : 'Unable to process', 
            modalMessage: this.props.lang === 'th' ? 'ไม่สามารถส่งข้อความได้' : 'Message could not be sent', 
            modalButtonLabel: this.props.lang === 'th' ? 'ปิด' : 'Close'
          });
        }
      });
  }

  render() {
    return (
      <>
        {this.props.contents.map((item, index_form) => (
          <section id="form" key={index_form} className="section section-form pt-5 pb-5 bg-img iov-container" style={{ backgroundImage: "url('" + urlApi + '/storage/media/' + item.set4 + "')" }}>
            <div className="iov-bg ibg-dark"></div>
            <Container className="iov-content">
              <Row>
                <Col md={12} className="p-5">

                  <>
                    <h5><hr />{item.set1}</h5>
                    <h2 className='mb-2'>{item.set2}</h2>
                    <p className='mb-4'>{item.set3}</p>
                  </>

                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col lg={4} md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            className="radius-2"
                            name="subject"
                            defaultValue={this.state.subject}
                            value={this.state.subject}
                            onChange={this.handleInputChange}
                            placeholder={this.props.lang === 'th' ? "พิมพ์คำถามที่ต้องการสอบถาม" : "Enter the question you want to ask."}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2} md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            className="radius-2"
                            name="name"
                            defaultValue={this.state.name}
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            placeholder={this.props.lang === 'th' ? "ชื่อผู้ติดต่อ" : "Contact name"}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            className="radius-2"
                            name="contact_back"
                            defaultValue={this.state.contact_back}
                            value={this.state.contact_back}
                            onChange={this.handleInputChange}
                            placeholder={this.props.lang === 'th' ? "Email/เบอร์โทร/Line ID" : "Email/Phone/Line ID"}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2} md={4} sm={12}>
                        <button type="submit" className="btn btn-primary w-100 fw-bold">
                          <BiPaperPlane size={24} color={'#A0DCFE'} /> {this.props.lang === 'th' ? "ส่งข้อความ" : "Send Message"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </section>
        ))}

        <Modal size="sm" show={this.state.modalShow} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary">{ this.state.modalTitle }</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark">
            { this.state.modalMessage }
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn ibt-primary px-4" onClick={this.handleClose}>
              { this.state.modalButtonLabel }
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default SectionForm