import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TbPhoneCall } from 'react-icons/tb';
import { TiLocationOutline, TiMail } from 'react-icons/ti';
import { FaFacebookF, FaFacebookMessenger } from 'react-icons/fa';
import { BsLine } from 'react-icons/bs';

export class Footer extends Component {
  render() {
    return (
      <>
        <section className='footer pt-5 px-3'>
          <Container fluid>
            <Row>
              <Col lg={4} md={6}>
                <h2>
                  <a href={"tel:" + this.props.phone}><TbPhoneCall className="me-3" color={'#A0DCFE'}/>{this.props.phone}</a>
                </h2>
              </Col>
              <Col lg={5} md={6}>
                <ul className="contact pb-3">
                  <li className="d-flex">
                    <div><TiLocationOutline size={20} color={'#A0DCFE'}/></div>
                    <div className="ms-2">{this.props.address}</div>
                  </li>
                  <li className="d-flex">
                    <div><TiMail size={20} color={'#A0DCFE'}/></div>
                    <div className="ms-2"><a href={"mailto:" + this.props.email}>{this.props.email}</a></div>
                  </li>
                </ul>
              </Col>
              <Col lg={{span: 3, offset: 0}} md={{span: 6, offset: 6}}>
                <ul className="social">
                  <li className="ms-2">
                    <a href={this.props.linelink} target="_blank" rel="noreferrer"><BsLine color={'#FFF'} size={26}/></a>
                  </li>
                  <li className="ms-2">
                    <a href={this.props.facebook} target="_blank" rel="noreferrer"><FaFacebookF color={'#FFF'} size={26}/></a>
                  </li>
                  <li className="ms-2">
                    <a href={this.props.messenger} target="_blank" rel="noreferrer"><FaFacebookMessenger color={'#FFF'} size={26}/></a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Footer