import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/core.css';
import { Home } from './Pages';
import ScrollToTop from './Components/ScrollToTop';

export class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter basename={''}>
          <ScrollToTop>
            <div className="wrapper">
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </div>
          </ScrollToTop>
        </BrowserRouter>
      </>
    )
  }

}

export default App