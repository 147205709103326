import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import bg from '../Images/bg/bg-wt.webp';

export class SectionGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalTitle: '',
      modalImage: '',
      modalMessage: '',
      modalShow: false,
      modalGallery: []
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  viewMore(v_item) {
    console.log(v_item);
    this.setState({
      modalTitle: v_item.name,
      modalMessage: v_item.detail,
      modalImage: v_item.row_value,
      modalGallery: v_item.contents
    });
    this.handleShow();
  }

  viewImage(v_item) {
    console.log(v_item);
    this.setState({
      modalImage: v_item.row_value
    })
  }

  render() {
    return (
      <>
        <section className="section section-gallery p-0" id="gallery" style={{backgroundColor: '#E3DBCC', backgroundImage: `url(${bg})`}}>
          <Container fluid>
            <Row>
              {this.props.contents.map((item, index) => (
                <Col lg={3} md={4} className="text-center p-0" key={index} onClick={() => this.viewMore(item)}>
                  <div>
                    {/* <div className="img-frame mb-3" style={{backgroundImage: "url('" + urlApi + '/storage/media/' + item.set3 + "')"}}></div> */}
                    <div className="bg-img ratio-4x3" style={{ backgroundImage: "url('" + urlApi + '/storage/gallery/' + item.row_value + "')" }}></div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <Modal show={this.state.modalShow} onHide={this.handleClose} fullscreen={true}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary text-center w-100">{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark p-5" scrollable={true}>
            <Row className="mb-3">
              <Col sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                <img src={urlApi + '/storage/gallery/' + this.state.modalImage} className="pb-5 w-100 rounded" alt={this.state.modalTitle} />
              </Col>
              <Col sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className="tx-service-modal">
                {this.state.modalMessage}
              </Col>
            </Row>
            <Row className="mb-5">
              {this.state.modalGallery.map((item, index) => (
              <Col sm={{ span: 2, offset: 0 }} md={{ span: 1, offset: 0 }} xl={{ span: 1, offset: 0 }} className="text-center" key={index}>
                <div className="ict-gallery ratio-16x9"
                  style={{ backgroundImage: "url('" + urlApi + '/storage/gallery/' + item.row_value + "')" }} 
                  onClick={() => this.viewImage(item)}
                ></div>
              </Col>
              ))}
            </Row>
            <Row>
              <Col className="text-center">
                <Button className="btn btn-sm ibt-primary px-3" onClick={this.handleClose}>กลับสู่หน้าหลัก</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default SectionGallery