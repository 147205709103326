import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Carousel } from 'react-bootstrap';
import Logo from '../Images/logo/logo.webp';

export class SectionCarousel extends Component {
  scrollToTarget(v_target) {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125, 
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <>
        <Container fluid className="px-0 bg-light">
            <Carousel>
              { this.props.contents.map((item, index) => (
              <Carousel.Item className="p-5 ratio-21x9" key={index}>
                <Carousel.Caption className="text-end">
                  <img src={Logo} alt='Slide logo' />
                  <h1 className="ifn-header itx-primary">{parse(item.name)}</h1>
                  <h4 className="itx-primary">{item.detail}</h4>
                </Carousel.Caption>
              </Carousel.Item>
              )) }
            </Carousel>
        </Container>
      </>
    )
  }
}

export default SectionCarousel