import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { TbPhone, TbMail } from 'react-icons/tb';
import { FaLine } from 'react-icons/fa';
import qrCode from '../Images/qr.jpg';
import certify from '../Images/certify/5127188F-4DAE-4E13-A926-B99FDDD5E485.jpg';

export class SectionContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  handleClick() {
    this.handleShow();
  }

  render() {
    return (
      <>
        <section id="contact" className="section section-contact ibg-secondary">
          <Container>
            <Row>
              <Col md={12} className="text-center itx-white">
                <h4>สวนของ นายปิติ เอี่ยมจำรูญลาภ</h4>
                <h5 className="mb-2">รหัสทะเบียนเกษตรเลขที่ 103202-0363-1-2</h5>
                <p className="pb-3">
                  <button type="button" className='view-certify' onClick={this.handleClick}>
                    หนังสือรับรองการขึ้นทะเบียนเข้าร่วมโครงการสนับสนุนการปลูกไม้เศรษฐกิจ
                  </button>
                </p>
                <p className="mb-0">ผู้ดูแลสวนฯ นายศิริ เอี่ยมจำรูญลาภ</p>
                <p>45 หมู่ที่ 10 ตำบลกุดจอก อำเภอบัวใหญ่ นครราชสีมา </p>
                <ul className='p-0 m-0'>
                  <li className='px-2 d-inline-block'>
                    <TbPhone size={24} /> <a href="tel:081-875-3351">081-875-3351</a>
                  </li>
                  <li className='px-2 d-inline-block'>
                    <TbMail size={24} /> <a href="mailto:siribert@yahoo.com">siribert@yahoo.com</a>
                  </li>
                  <li className='px-2 d-inline-block'>
                    <FaLine size={24} /> siribert
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <div className='w-100 d-flex justify-content-center'>
                  <img src={qrCode} style={{ width: '100px', borderRadius: '5px' }} />
                  <div className='ms-4'>
                    <h4 className='itx-primary'>พร้อมเพย์</h4>
                    <p className='mb-0'>ชื่อบัญชี SIRI AUMJAMROONLARB</p>
                    <p className='mb-0'>เลขบัญชี 0818753351</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal show={this.state.modalShow} onHide={this.handleClose} fullscreen={true}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary text-center w-100">
              หนังสือรับรองการขึ้นทะเบียนเข้าร่วมโครงการสนับสนุนการปลูกไม้เศรษฐกิจ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark p-5" scrollable={true}>
            <Row className="mb-3">
              <Col>
                <img src={certify} className="pb-5 w-100 rounded" />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button className="btn btn-sm ibt-primary px-3" onClick={this.handleClose}>กลับสู่หน้าหลัก</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default SectionContact