import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { SectionNavbar, SectionCarousel, SectionAbout, SectionService, SectionFAQ, SectionContact, Copyright, SectionNavbarCorner, SectionGallery } from '../Components';
import SectionCertify from '../Components/SectionCertify';
import SectionClient from '../Components/SectionClient';
import logo from '../Images/logo/logo_bg-tp.webp';
import favicon from '../Images/logo/favicon.png';
import { Helmet } from 'react-helmet';
import Mockup1 from '../Images/mockup/1.jpg';
import MockupP1 from '../Images/mockup/p1.jpg';
import MockupP2 from '../Images/mockup/p2.jpg';
import MockupP3 from '../Images/mockup/p3.jpg';
import Gallery1 from '../Images/mockup/g1.jpg';
import Gallery2 from '../Images/mockup/g2.jpg';
import Gallery3 from '../Images/mockup/g3.jpg';
import Gallery4 from '../Images/mockup/g4.jpg';
import SectionArticle from '../Components/SectionArticle';

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTemporary: false,
      contents: {
        contact: {
          address: '',
          phone: '',
          facebook: '',
          line: '',
          linelink: ''
        },
        lang: 'th',
        info: {
          title: '', 
          "meta-keywords": '', 
          'meta-description': ''
        }, 
        datas: {
          slides: [],
          about: {}, 
          certify: [], 
          items: [],
          faq: {}, 
          galleries: []
        }
      }
    }
  }

  async componentDidMount() {
    console.log("componentDidMount", urlApi + "/?module=home&call=get-content");
    const url = urlApi + "/?module=home&call=get-content";
    await fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("App", result);
        this.setState({ contents: result.results });
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.state.contents.info.title}</title>
          <link rel='icon' type="image/png" href={favicon} />
          <meta name="description" content={this.state.contents.info["meta-description"]} />
          <meta name="keywords" content={this.state.contents.info["meta-keywords"]} />
          <meta name="author" content="Piti Organic" />
        </Helmet>
        <div className="design-2-2">
          <SectionNavbar logo={logo} lang={this.state.contents.lang}
            line={this.state.contents.contact.line}
            linelink={this.state.contents.contact.linelink}
            phone={this.state.contents.contact.phone}
            email={this.state.contents.contact.email}
            messenger={this.state.contents.contact.messenger}
          />
          <SectionCarousel lang={this.state.contents.lang} contents={this.state.contents.datas.slides} />
          <SectionAbout contents={this.state.contents.datas.about} />
          <SectionCertify id="certify" contents={this.state.contents.datas.certify} />
          <SectionService id="product" contents={this.state.contents.datas.items} />
          <SectionArticle id="article" contents={this.state.contents.datas.articles} />
          <SectionFAQ lang={this.state.contents.lang} contents={this.state.contents.datas.faq} />
          <SectionClient id="client" contents={this.state.contents.datas.client} />
          <SectionGallery id="gallery" contents={this.state.contents.datas.galleries} />
          <SectionContact
            contents={this.state.contents.datas.contact_home}
            address={this.state.contents.contact.address}
            line={this.state.contents.contact.line}
            linelink={this.state.contents.contact.linelink}
            phone={this.state.contents.contact.phone}
            email={this.state.contents.contact.email}
            facebook={this.state.contents.contact.facebook}
            messenger={this.state.contents.contact.messenger}
            googlemaps={this.state.contents.contact.googlemaps}
          />
          <Copyright lang={this.state.contents.lang} />
          <SectionNavbarCorner />
        </div>
      </>
    )
  }
}

export default Home