import React from 'react';
import { Col } from 'react-bootstrap';

const EmbedPDF = ({ src, width, height }) => {
  return (
    <>
      <Col>
        {src !== '' ? (
        <embed src={src} type="application/pdf" width={width} height={height} className="mb-3" />
        ) : ('')}
      </Col>
    </>
  )
}

EmbedPDF.defaultProps = {
  src: '', 
  width: '100%', 
  height: '600'
}

export default EmbedPDF