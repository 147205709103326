import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import $ from 'jquery';

export class SectionNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon_size: 24, 
      showOffcanvas: false
    }
  }

  async componentDidMount() {
    $(document).ready(function(){
      $(window).scroll(function(){
        if(window.scrollY > 0){
          $(".navbar").addClass("viewing-content");
        }else{
          $(".navbar").removeClass("viewing-content");
        }
      });
    });
  }

  scrollToTarget(v_target) {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125, 
      behavior: 'smooth'
    });
    this.closeOffcanvas();
  }

  async changeLanguage(v_lang) {
    console.log("componentDidMount", urlApi + "/?module=web&call=language-change", v_lang);
    var form_data = new FormData();
    form_data.append("lang", v_lang);

    const url = urlApi + "/?module=web&call=language-change";
    await fetch(url, {
      method: 'POST', 
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("changeLanguage", result);
        window.location.reload();
      });
  }

  openOffcanvas = () => {
    this.setState({
      showOffcanvas: true,
    });
  };

  closeOffcanvas = () => {
    this.setState({
      showOffcanvas: false,
    });
  };

  render() {
    return (
      <>
        <Navbar expand="xl" sticky="top" className="flex-column pt-0 pb-0 bg-light">
          <Container fluid className="pt-2 pb-2 px-3">
            <Navbar.Brand href="/">
              <img src={this.props.logo} className="logo" alt="Piti Organic" />
            </Navbar.Brand>
            {/* <Nav.Link href="#" className="itx-primary fw-bold ms-auto d-none d-sm-inline-block d-lg-none" onClick={() => this.changeLanguage(this.props.lang === "th" ? "en" : "th")}>
              {this.props.lang === 'th' ? 'English' : 'ภาษาไทย'}
            </Nav.Link> */}
            <Navbar.Toggle aria-controls="offcanvasNavbar" className="ms-0 ms-sm-3" onClick={this.openOffcanvas} />
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
              show={this.state.showOffcanvas} 
              onHide={this.closeOffcanvas}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel">
                  {this.props.lang === 'th' ? 'เมนูหลัก' : 'Main menu'}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                  <Link to="/" className="nav-link active">{this.props.lang === 'th' ? 'หน้าแรก' : 'Home'}</Link>
                  <Nav.Link onClick={() => this.scrollToTarget("about")}>{this.props.lang === 'th' ? 'เกี่ยวกับเรา' : 'About us'}</Nav.Link>
                  <Nav.Link onClick={() => this.scrollToTarget("product")}>{this.props.lang === 'th' ? 'ผลิตภัณฑ์ออร์แกนิค' : 'Products and Services'}</Nav.Link>
                  <Nav.Link onClick={() => this.scrollToTarget("article")}>{this.props.lang === 'th' ? 'คลังความรู้' : 'Knowledge Base'}</Nav.Link>
                  <Nav.Link onClick={() => this.scrollToTarget("client")}>{this.props.lang === 'th' ? 'ลูกค้าของเรา' : 'Our Client'}</Nav.Link>
                  <Nav.Link onClick={() => this.scrollToTarget("gallery")}>{this.props.lang === 'th' ? 'ผลงาน' : 'Gallery'}</Nav.Link>
                  <Nav.Link onClick={() => this.scrollToTarget("contact")}>{this.props.lang === 'th' ? 'ติดต่อเรา' : 'Contact us'}</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default SectionNavbar