import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Deco1 from '../Images/deco/deco_1.png';
import Deco2 from '../Images/deco/deco_2.png';
import Deco3 from '../Images/deco/deco_3.png';
import Deco4 from '../Images/deco/deco_4.png';
import Deco5 from '../Images/deco/deco_5.png';
import Deco6 from '../Images/deco/deco_6.png';
import Deco7 from '../Images/deco/deco_7.png';
import MockupImg from '../Images/bg/bg_color-2.webp';

const SectionClient = ({ contents }) => {
  let decoArray = [Deco1, Deco2, Deco3, Deco4, Deco5, Deco7];

  // Retrieve the element at the random index
  let randomElement = decoArray[Math.floor(Math.random() * decoArray.length)];

  return (
    <>
      <section className="pt-5 pb-5 mb-5 section section-service bg-white bg-img" id="client">
        <Container>
          <Row>
            <Col className="text-center">
              <h5><img src={Deco6} className="me-3" style={{ height: '2rem' }} alt="deco" />ลูกค้าของเรา</h5>
              <h2 className='mb-5'>ขอขอบคุณลูกค้าที่ไว้วางใจในผลิตภัณฑ์ออร์แกนิคของเรา</h2>
            </Col>
          </Row>
          <Row className='pt-5'>
            {contents.map((item, index) => (
              <Col md={{ span: 3 }} className="text-center mb-5 px-5">
                <div className='client-card position-relative'>
                  <img src={urlApi + '/storage/media/' + item.set2} className='client-img w-100' alt="ลูกค้าของเรา" key={index} />
                  <img src={decoArray[Math.floor(Math.random() * decoArray.length)]} className='client-deco' />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionClient.defaultProps = {
  contents: []
}

export default SectionClient