import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IoArrowUpSharp } from 'react-icons/io5';
// import { RiQuestionAnswerLine } from 'react-icons/ri';

export class SectionNavbarCorner extends Component {
  render() {
    return (
      <>
        <ul className="navbar-corner">
          <li>
            <Link to='/'><button><IoArrowUpSharp size={30} /></button></Link>
          </li>
          {/* <li>
            <button><RiQuestionAnswerLine size={30} /></button>
          </li> */}
        </ul>
      </>
    )
  }
}

export default SectionNavbarCorner