import React from 'react';
import { urlApi } from '../Constants/Global';
import { Row } from 'react-bootstrap';
import ContentStyle from './ContentStyle';
import ContentText from './ContentText';
import ContentImage from './ContentImage';
import ContentEmbedPDF from './ContentEmbedPDF';
import DynamicComponent from './DynamicComponent';
import ContentEmbed from './ContentEmbed';

const DisplayContent = (props) => {
  console.log("DisplayContent :", props);

  const storage = props.path + urlApi + '/storage/article/';

  const convertToCamelCase = (inputString) => {
    const words = inputString.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join('');
  }

  return (
    <>
      <ContentStyle />
      {props.contents.map((content, index) => {
        let result;

        switch (content.row_class) {
          case 'text':
            result = <ContentText content={content.detail1} />;
            break;
          case 'image':
            result = <ContentImage src={storage + content.detail1} />;
            break;
          case 'text-text':
            result = <>
              <ContentText content={content.detail1} size={6} />
              <ContentText content={content.detail2} size={6} />
            </>;
            break;
          case 'image-image':
            result = <>
              <ContentImage src={storage + content.detail1} size={6} />
              <ContentImage src={storage + content.detail2} size={6} />
            </>;
            break;
          case 'image-text':
            result = <>
              <ContentImage src={storage + content.detail1} size={6} />
              <ContentText content={content.detail2} size={6} />
            </>;
            break;
          case 'text-image':
            result = <>
              <ContentText content={content.detail1} size={6} />
              <ContentImage src={storage + content.detail2} size={6} />
            </>;
            break;
          case 'pdfviewer':
            result = <ContentEmbedPDF src={storage + content.detail1} />;
            break;
          case 'html':
            result = <ContentEmbed content={content.detail1} size={12} />;
            break;
          case 'customsetup':
            result = <DynamicComponent directory={"CustomSetup"} componentName={convertToCamelCase(content.detail1)} contents={content.custom_setup} />;
            break;
          default:
            content.row_class.split('-').map((col, index) => {
              result += col;
              return result;
            });
        }

        return (
          <Row key={index} className="pb-3">
            {result}
          </Row>
        );
      })}
    </>
  )
}

DisplayContent.defaultProps = {
  contents: [],
  path: ''
}

export default DisplayContent