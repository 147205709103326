import React, { Component } from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Form, Accordion } from 'react-bootstrap';

export class SectionFAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '', 
      contents: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps(props) {
    console.log("SectionFAQ", props.contents);
    this.setState({contents: props.contents});
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });

    console.log(target.value);
    var form_data = new FormData();
    form_data.append("keyword", target.value);

    var url = urlApi + '/?module=home&call=filter-faq';

    fetch(url, {
      method: 'POST', 
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("filter-faq", result);
        this.setState({contents: result.results.datas});
      });
  }

  render() {
    return (
      <>
        <section id="faq" className="section section-faq">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="text-center">
                <h6 className='header'>คำถามที่พบบ่อย</h6>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="text" 
                    className="radius-2 faq-filter" 
                    placeholder={this.props.lang === 'th' ? "กรอกคีย์เวิร์ดเพื่อหาข้อมูล" : 'Please, enter keywords to find your content.'}
                    defaultValue={this.state.filter}
                    onChange={this.handleInputChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 8, offset: 2 }} md={12}>
                {this.state.contents.length > 0 ? 
                <Accordion defaultActiveKey={0} flush>
                  { this.state.contents.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.set1}</Accordion.Header>
                    <Accordion.Body>{item.set2}</Accordion.Body>
                  </Accordion.Item>
                  )) }
                </Accordion>
                : (
                  <>
                    <div className="text-center">
                      <h6>ขออภัยไม่พบข้อมูลที่ท่านต้องการ</h6>
                      <p>สอบถามข้อมูลเพิ่มเติม ติดต่อเรา<a href="/#" className="itx-primary" onClick={() => this.scrollToTarget("contact")}>ที่นี่</a></p>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default SectionFAQ